// Text form input

input[type=text] {
  height: 35px;
  width: 9.5em;
  padding: 0 8px;
  margin-right: 0.5em;
  vertical-align: middle;

  &.input-full-width {
    width: 100%;
    max-width: 100%;
  }
}

textarea, input[type=text] {
  border: 1px solid $ia-color-15;
  border-radius: 4px;
  transition: border-color .36s ease-in-out, box-shadow .36s ease-in-out;

  &:focus {
  outline: none !important;
  border: 1px solid $ia-color-21;
  box-shadow: 2px 2px 4px 0 $black_12;
  }

  &:disabled, fieldset[disabled] & {
    background-color: $ia-color-20;
    color: $ia-color-09;
  }
}
