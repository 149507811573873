
//colors
$ia-color-00: #FFFFFF;
$ia-color-01: #292F4F;
$ia-color-02: #3D4260;
$ia-color-03: #F2F2F2;
$ia-color-04: #4B566F;
$ia-color-05: #496CEC;
$ia-color-06: #212121;
$ia-color-07: #F5F6F7;
$ia-color-08: #CDCED6;
$ia-color-09: #70748A;
$ia-color-10: #9A9A9A;
$ia-color-11: #A9B2BD;
$ia-color-12: #6A6A6A;
$ia-color-13: #8AA7F5;
$ia-color-14: #E1E1E1;
$ia-color-15: #E9EAED;
$ia-color-16: #DA4453;
$ia-color-17: #4C7B67;
$ia-color-18: #B3E5FC;
$ia-color-19: #003C75;
$ia-color-20: #FFEBBA;
$ia-color-21: #765A20;
$ia-color-22: #F8BFC5;
$ia-color-23: #9A303B;

$black_12: rgba(0,0,0,0.12);
